import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Les assiettes volantes`}</h1>
    <p>{`Vous connaissez certainement ce numéro de cirque qui s'appelle les assiettes volantes ? Il s'agit de faire tourner sur des tiges flexibles des assiettes, le plus possible et sans qu'aucune ne tombe. On se prend à partager l'envie d'en rajouter encore et encore, conjugué avec une crainte grandissante de voir une assiette tomber. Le stress grandit avec le nombre d'assiettes en vol. Le numéro devient rapidement jubilatoire: une course effrénée entre les nouvelles assiettes à mettre en mouvement et celles qui oscillent dangereusement vers la chute. On hésite entre vouloir le succès de la tentative et l'envie d'entendre des assiettes se briser, car on sent bien que l'artiste sur-joue l'urgence et la panique. `}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAC/8QAFAEBAAAAAAAAAAAAAAAAAAAAA//aAAwDAQACEAMQAAABQJZLYXLULf/EABoQAAIDAQEAAAAAAAAAAAAAAAECAwQREgD/2gAIAQEAAQUCsllA51Ri2V6SIkS+/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQIf/aAAgBAwEBPwFxs//EABYRAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAgEBPwEIv//EABgQAAMBAQAAAAAAAAAAAAAAAAABMRAR/9oACAEBAAY/AuqMglCimf/EABwQAQADAAIDAAAAAAAAAAAAAAEAESEQMVGBof/aAAgBAQABPyG3g5IyzT9bDMKB1ECqb4gIfHH/2gAMAwEAAgADAAAAELDv/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qof/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCsa//EABwQAQEBAAEFAAAAAAAAAAAAAAERACExcZGh0f/aAAgBAQABPxAFwSch60M5zxQPukjCVZuiThGh769BGVSeLl3/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "image",
          "title": "image",
          "src": "/static/4b28c71f6ad0ea8d15c1f234253d9191/6aca1/BluePlasticPlates-m.jpg",
          "srcSet": ["/static/4b28c71f6ad0ea8d15c1f234253d9191/d2f63/BluePlasticPlates-m.jpg 163w", "/static/4b28c71f6ad0ea8d15c1f234253d9191/c989d/BluePlasticPlates-m.jpg 325w", "/static/4b28c71f6ad0ea8d15c1f234253d9191/6aca1/BluePlasticPlates-m.jpg 650w", "/static/4b28c71f6ad0ea8d15c1f234253d9191/e9f84/BluePlasticPlates-m.jpg 672w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Beaucoup de chefs d'entreprise que je rencontre me disent avoir l'impression de faire ce numéro tous les jours et 24/24. Les assiettes étant n'importe quel sujet critique: la trésorerie, les stocks, les ventes, un impayé, la démission soudaine d'un collaborateur, ou un message du banquier sur le répondeur.`}</p>
    <p>{`Levez la main ceux qui voit leur travail comme une succession de sprints vers la prochaine assiette qui va tomber. `}</p>
    <h2>{`Les indicateurs stratégiques`}</h2>
    <p><strong parentName="p">{`perceptible.fr`}</strong>{` aide le chef d'entreprise à identifier ses indicateurs stratégiques. Ceux qui permettent de projeter l'activité et de décider concrètement ce qui est le plus urgent à faire aujourd'hui pour préparer demain. `}</p>
    <p>{`Pour le marchand de glace, par exemple, ce sont la météo à 4 jours et les prix de ses concurrents dans un rayon de 500 m.`}</p>
    <h2>{`La représentation visuelle`}</h2>
    <p>{`Les chiffres sont utiles comme référence, mais ils peuvent aussi être un obstacle à la compréhension et à l'action. Le tableau de chiffres qui tient à jour en temps réel les coordonnées et vitesses angulaires des assiettes décrit complètement la situation mais n'est d'aucune utilité immédiate à la décision. `}</p>
    <p>{`Il faut plutôt un plan avec des minuteurs graphiques à la position de chaque assiette qui affichent le temps restant avant la chute.  `}</p>
    <h2>{`Tableau de bord à partir des bilans successifs`}</h2>
    <p>{`Les indicateurs stratégiques sont des choses très personnelles uniques à chaque entreprise. Il existe néanmoins un niveau commun à toutes les entreprises: `}<strong parentName="p">{`la finance`}</strong>{`. `}</p>
    <p><strong parentName="p">{`perceptible.fr`}</strong>{` offre un service de Diagnostic PME qui permet à partir de 3 à 5 bilans de visualiser les engrenages financiers qui transforment les commandes gagnées en: achat de matières premières, marge, valeur ajoutée, salaires, impôts et finalement rentabilité des capitaux propres. L'ambition est d'abord d'expliquer les pourquoi de la trajectoire, .... puis de prédire et d'ajuster.`}</p>
    <p>{`Elles sont mises à la queue leu leu sur plusieurs exercices pour faire apparaitre les `}<strong parentName="p">{`trajectoires`}</strong>{` des éléments clés. Ceux qui répondent aux questions importantes: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Où se joue la rentabilité de l'entreprise ?`}</strong>{`, `}</li>
      <li parentName="ul"><strong parentName="li">{`Cette baisse (résultat, valeur ajoutée, marge brute, ...) est elle le reflet de la diminution des commandes ou d'une moindre performance interne ?`}</strong>{`, `}</li>
      <li parentName="ul"><strong parentName="li">{`Où se trouve le goulet d'étranglement ?`}</strong>{`,`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <p>{`C'est la mise en lumière des indicateurs `}<strong parentName="p">{`comparables`}</strong>{`: Production par personne, part de la sous-traitance ou des moyens techniques, Valeur Ajoutée/ Production, ... qui permettent de comprendre les causes et leurs enchainements jusqu'au `}<strong parentName="p">{`bénéfice ou la perte`}</strong>{` de l'exercice en cours. Ces mêmes indicateurs sont aussi les bras de levier permettant d'agir sur le futur: `}<strong parentName="p">{`imaginer, simuler et décider`}</strong>{` les changements pour atteindre l'objectif.`}</p>
    <h2>{`Diagnostic PME`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAC3klEQVQoz22SS28TVxiG51+0Uv9E2fQfwCIVUlsJCVC7YFWBSgStQEKIBSIVkJQ0iXGwm4sdx8ExnpgKJxA7xjPxLfb4bo/lW5w6mXFCwakTkjRVL3o6mC57pEfv+32L5yzOEWyLaQY9BUa9BYbFPLedGQYeZ/+XO3NZvne97+/y7nyul/eMvO3M8uhpGuHd8oq9zi1XnWuOGv3TFb61V7k0WeGy0a/YqlyeqnDV/j6/MqmcHS1xbqzEeYMvjfnCuMqZEZUb9izCLUeaU3eLfPZDkdNDRc6OqYaoysUJQ+yo9y77brZBv63GdWeDS1M1rs7U+Wa6xtcTVfruFzg9WOg5rtsM4ZNAhkHXGsPzaww9jjPsTjD+NIPlWR6zN41JVBjzJBj9D5OYZPznDA8XFEyepLFTGDFyyHDMLikIG+t1ipkUak4hFZeMTKH90qC92aSQSaLEI8ihILFomLAUpFLKUy7miEZlpNUQ8WiUcChAIiJRKuQQtjQd0feSQLhEqqTx9k96p9Pdo9v9jb+NrhdCpKf6Of7jL/4x5u7+AfvNGJ3KMoXWOtFGm/T2W/TdfYQdvUly5gzPf+wjaP6CnOsiW4EBtpslunsHPflOMYR04wTK2DmqvgfsHf5OZ9XHjmhFl+bYVBbYKj7j1+0mgrZeI9b3EfFPPyBz4QThvg/xfyywIS/yeqdFa9VBWbxDZOAk8s1PSD74nP2jYzrhRTSPldrcIxrOh3S8E+w2VIRXbZ3281laC1Y034yBHd1rpbO5Qfe13hPqCZE3ZYl2dhk9H2T34IjDdovDjQrH2jpHmw2DOm/aGkJgZQXv0jJyIkVgNcJKOMZKZA2P6CEkh5FiCs75BZwuD74XAVraNo2Y3HuMaFIhKMk9wrE1SmoZYWlpEdvUJBazmaH793A6HExYLPxktRhSEb//BXPOWUyjI7ifuCnnc7ycNOOYnsbr9eJ2uwn4/ciy8UNUlX8Bzf+zuI9vyJAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Leaf",
          "title": "Leaf",
          "src": "/static/7733313ad9c62fb689452bd19a904af9/a6d36/Leaf.png",
          "srcSet": ["/static/7733313ad9c62fb689452bd19a904af9/222b7/Leaf.png 163w", "/static/7733313ad9c62fb689452bd19a904af9/ff46a/Leaf.png 325w", "/static/7733313ad9c62fb689452bd19a904af9/a6d36/Leaf.png 650w", "/static/7733313ad9c62fb689452bd19a904af9/23296/Leaf.png 675w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`perceptible.fr`}</strong>{` propose un service de diagnostic PME non intrusif qui, à partir de 1 à 3 liasses fiscales de l'entreprise, fournit un rapport visuel de l'évolution des paramètres clés.`}</p>
    <p>{`Pour tout renseignement: `}<a parentName="p" {...{
        "href": "mailto:alain.roan@perceptible.fr"
      }}>{`alain.roan@perceptible.fr`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      